import React from 'react';
import { Calendar, MapPin } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { BRAND, IMAGES } from '../../config/constants';
import { LocationSelect } from '../ui/LocationSelect';

export function Hero() {
  const { t } = useTranslation();

  return (
    <div className="relative h-[600px] flex items-center">
      <div className="absolute inset-0 z-0">
        <img
          src={IMAGES.hero}
          alt="Luxury car"
          className="w-full h-full object-cover brightness-50"
        />
      </div>
      <div className="container mx-auto px-4 z-10">
        <div className="max-w-2xl text-white">
          <h1 className="text-5xl font-bold mb-6">
            {t('hero.title')}
          </h1>
          <p className="text-xl mb-8">
            {t('hero.subtitle')}
          </p>
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <form className="space-y-4">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div className="space-y-2">
                  <label className="block text-gray-700">{t('hero.pickupLocation')}</label>
                  <LocationSelect locations={BRAND.locations} />
                </div>
                <div className="space-y-2">
                  <label className="block text-gray-700">{t('hero.dates')}</label>
                  <div className="relative">
                    <Calendar className="absolute left-3 top-3 text-gray-400" size={20} />
                    <input
                      type="text"
                      placeholder={t('hero.datesPlaceholder')}
                      className="w-full pl-10 pr-4 py-2 border rounded-md focus:ring-2 focus:ring-red-600"
                    />
                  </div>
                </div>
              </div>
              <button className="w-full bg-red-600 text-white py-3 rounded-md font-medium hover:bg-red-700 transition-colors">
                {t('hero.searchButton')}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}