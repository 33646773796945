import React from 'react';
import { useTranslation } from 'react-i18next';
import { CalendarCheck, Car, CreditCard, MapPin } from 'lucide-react';

export function HowItWorks() {
  const { t } = useTranslation();

  const steps = [
    {
      icon: CalendarCheck,
      translationKey: 'choose',
    },
    {
      icon: MapPin,
      translationKey: 'location',
    },
    {
      icon: CreditCard,
      translationKey: 'payment',
    },
    {
      icon: Car,
      translationKey: 'enjoy',
    },
  ];

  return (
    <section id="how-it-works" className="py-16 bg-white">
      <div className="container mx-auto px-4">
        <h2 className="text-4xl font-bold text-center mb-4">{t('howItWorks.title')}</h2>
        <p className="text-gray-600 text-center mb-12 max-w-2xl mx-auto">
          {t('howItWorks.subtitle')}
        </p>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {steps.map((step, index) => (
            <div key={index} className="text-center">
              <div className="mb-6 relative">
                <step.icon className="w-16 h-16 mx-auto text-red-600" />
                {index < steps.length - 1 && (
                  <div className="hidden lg:block absolute top-8 left-[60%] w-full h-0.5 bg-gray-200" />
                )}
              </div>
              <h3 className="text-xl font-semibold mb-2">
                {t(`howItWorks.steps.${step.translationKey}.title`)}
              </h3>
              <p className="text-gray-600">
                {t(`howItWorks.steps.${step.translationKey}.description`)}
              </p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}