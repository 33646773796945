import React from 'react';
import { MapPin } from 'lucide-react';
import { useTranslation } from 'react-i18next';

interface LocationSelectProps {
  locations: string[];
}

export function LocationSelect({ locations }: LocationSelectProps) {
  const { t } = useTranslation();

  return (
    <div className="relative">
      <MapPin className="absolute left-3 top-3 text-gray-400" size={20} />
      <select
        className="w-full pl-10 pr-4 py-2 border rounded-md focus:ring-2 focus:ring-red-600 appearance-none bg-white"
        defaultValue=""
      >
        <option value="" disabled>{t('hero.locationPlaceholder')}</option>
        {locations.map((location) => (
          <option key={location} value={location}>
            {location}
          </option>
        ))}
      </select>
    </div>
  );
}