import React from 'react';
import { useTranslation } from 'react-i18next';
import { BRAND } from '../config/constants';

export function About() {
  const { t } = useTranslation();

  return (
    <section id="about" className="py-16 bg-gray-50">
      <div className="container mx-auto px-4">
        <div className="max-w-3xl mx-auto">
          <h2 className="text-4xl font-bold text-center mb-4">{t('about.title')}</h2>
          <p className="text-gray-600 text-center mb-12">{t('about.subtitle')}</p>
          
          <div className="space-y-8">
            <div>
              <h3 className="text-2xl font-semibold mb-4">{t('about.history.title')}</h3>
              <p className="text-gray-700">{t('about.history.description')}</p>
            </div>

            <div>
              <h3 className="text-2xl font-semibold mb-4">{t('about.mission.title')}</h3>
              <p className="text-gray-700">{t('about.mission.description')}</p>
            </div>

            <div>
              <h3 className="text-2xl font-semibold mb-4">{t('about.values.title')}</h3>
              <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                {['excellence', 'reliability', 'innovation'].map((value) => (
                  <div key={value} className="bg-white p-6 rounded-lg shadow-md">
                    <h4 className="text-xl font-semibold mb-2">
                      {t(`about.values.${value}.title`)}
                    </h4>
                    <p className="text-gray-600">
                      {t(`about.values.${value}.description`)}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}