export const BRAND = {
  name: 'OclockCars',
  locations: ['Marrakech', 'Rabat', 'Fes', 'Casablanca', 'Agadir'],
  primaryColor: 'red-600',
  secondaryColor: 'gray-100',
};

export const IMAGES = {
  hero: 'https://images.unsplash.com/photo-1494976388531-d1058494cdd8?auto=format&fit=crop&w=2000&q=80',
  logo: 'https://images.unsplash.com/photo-1583121274602-3e2820c69888?auto=format&fit=crop&w=500&q=80',
};