import React from 'react';
import { Vehicle } from '../../types';
import { useTranslation } from 'react-i18next';
import { Check } from 'lucide-react';

interface VehicleCardProps {
  vehicle: Vehicle;
  onSelect: (id: string) => void;
}

export function VehicleCard({ vehicle, onSelect }: VehicleCardProps) {
  const { t } = useTranslation();

  return (
    <div className="bg-white rounded-lg shadow-md overflow-hidden">
      <img
        src={vehicle.image}
        alt={vehicle.name}
        className="w-full h-48 object-cover"
      />
      <div className="p-6">
        <div className="flex justify-between items-start mb-4">
          <div>
            <h3 className="text-xl font-semibold">{vehicle.name}</h3>
            <p className="text-gray-600">{vehicle.type}</p>
          </div>
          <div className="text-right">
            <p className="text-2xl font-bold text-red-600">{vehicle.price} EURO</p>
            <p className="text-sm text-gray-600">{t('vehicles.perDay')}</p>
          </div>
        </div>
        <p className="text-gray-700 mb-4">{vehicle.description}</p>
        <div className="space-y-2 mb-6">
          {vehicle.features.map((feature, index) => (
            <div key={index} className="flex items-center text-gray-700">
              <Check size={16} className="text-red-600 mr-2" />
              <span>{feature}</span>
            </div>
          ))}
        </div>
        <button
          onClick={() => onSelect(vehicle.id)}
          className="w-full bg-red-600 text-white py-2 rounded-md hover:bg-red-700 transition-colors"
        >
          {t('vehicles.select')}
        </button>
      </div>
    </div>
  );
}