import { Vehicle } from '../types';

export const vehicles: Vehicle[] = [
  {
    id: 'bmw-7',
    name: 'BMW 7 Series',
    type: 'Luxury Sedan',
    price: 350,
    image: 'https://images.unsplash.com/photo-1555215695-3004980ad54e?auto=format&fit=crop&w=2070&q=80',
    description: 'Experience ultimate luxury with the BMW 7 Series, featuring premium leather interior and advanced driving assistance.',
    features: ['Leather Interior', 'Automatic Transmission', 'GPS Navigation', 'Heated Seats'],
    available: true
  },
  {
    id: 'mercedes-s',
    name: 'Mercedes-Benz S-Class',
    type: 'Premium Sedan',
    price: 400,
    image: 'https://images.unsplash.com/photo-1618843479313-40f8afb4b4d8?auto=format&fit=crop&w=2070&q=80',
    description: 'The pinnacle of luxury, offering exceptional comfort and cutting-edge technology.',
    features: ['Premium Sound System', 'Massage Seats', 'Panoramic Roof', 'Driver Assistance'],
    available: true
  },
  {
    id: 'porsche-911',
    name: 'Porsche 911',
    type: 'Sports Car',
    price: 500,
    image: 'https://images.unsplash.com/photo-1503376780353-7e6692767b70?auto=format&fit=crop&w=2070&q=80',
    description: 'Pure driving pleasure with iconic design and outstanding performance.',
    features: ['Sport Mode', 'Carbon Fiber Interior', 'Launch Control', 'Sport Exhaust'],
    available: true
  }
];