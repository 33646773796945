export const en = {
  header: {
    vehicles: 'Vehicles',
    howItWorks: 'How it Works',
    about: 'About',
    contact: 'Contact',
    login: 'Login'
  },
  hero: {
    title: 'Premium Car Rental in Morocco',
    subtitle: 'Experience Moroccan precision and luxury in every journey',
    pickupLocation: 'Pickup Location',
    dates: 'Dates',
    searchButton: 'Search for a Vehicle',
    locationPlaceholder: 'Select location',
    datesPlaceholder: 'Select your dates'
  },
  features: {
    title: 'Why Choose OclockCars ?',
    booking: {
      title: '24/7 Booking',
      description: 'Book your vehicle anytime through our online platform'
    },
    insurance: {
      title: 'Swiss Insurance',
      description: 'Comprehensive coverage with Swiss quality standards'
    },
    quality: {
      title: 'Premium Fleet',
      description: 'Luxury vehicles maintained to Swiss precision standards'
    },
    support: {
      title: 'Local Support',
      description: 'Expert Swiss team available in multiple languages'
    }
  },
  vehicles: {
    title: 'Our Premium Fleet',
    subtitle: 'Choose from our selection of luxury vehicles',
    perDay: 'per day',
    select: 'Select Vehicle',
    features: 'Features',
    availability: 'Check Availability'
  },
  howItWorks: {
    title: 'How it Works',
    subtitle: 'Rent your premium vehicle in 4 simple steps',
    steps: {
      choose: {
        title: 'Choose Your Vehicle',
        description: 'Select from our premium fleet of luxury vehicles'
      },
      location: {
        title: 'Select Location',
        description: 'Choose from our convenient pickup locations'
      },
      payment: {
        title: 'Secure Payment',
        description: 'Complete your booking with our secure payment system'
      },
      enjoy: {
        title: 'Enjoy Your Ride',
        description: 'Experience Swiss luxury and precision'
      }
    }
  },
  about: {
    title: 'About OclockCars',
    subtitle: 'Setting the standard for luxury car rental in Morocco',
    history: {
      title: 'Our History',
      description: 'Founded on the principles of Moroccan excellence, OclockCars has been providing premium car rental services since 1970. Our commitment to quality and customer satisfaction has made us a leader in the luxury car rental market.'
    },
    mission: {
      title: 'Our Mission',
      description: 'To provide an unparalleled luxury car rental experience that embodies Swiss precision, reliability, and excellence in every aspect of our service.'
    },
    values: {
      title: 'Our Values',
      excellence: {
        title: 'Excellence',
        description: 'We maintain the highest standards in our fleet and service'
      },
      reliability: {
        title: 'Reliability',
        description: 'Count on us for punctual and dependable service'
      },
      innovation: {
        title: 'Innovation',
        description: 'Continuously improving our services with latest technologies'
      }
    }
  },
  contact: {
    title: 'Contact Us',
    subtitle: 'We are here to assist you with any questions',
    form: {
      title: 'Send us a Message',
      name: 'Your Name',
      email: 'Email Address',
      message: 'Your Message',
      submit: 'Send Message'
    },
    info: {
      title: 'Contact Information',
      address: {
        title: 'Amal 5 N 118 El Massira Cym',
        line1: 'Yacoub El Mansour',
        line2: 'Rabat, Morocco'
      },
      phone: {
        title: 'Phone'
      },
      email: {
        title: 'Email'
      }
    }

  },
  footer: {
    quickLinks: 'Rapid links',
    locations: 'Locations',
    contact: 'Contact',
    description: 'Ready for a ride. We are ready',
    rights: 'All rights reserved'
  }
};