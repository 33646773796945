import React from 'react';
import { Car } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { BRAND } from '../../config/constants';

export function Header() {
  const { t } = useTranslation();

  return (
    <header className="bg-red-600 text-white">
      <nav className="container mx-auto px-4 py-4 flex items-center justify-between">
        <div className="flex items-center space-x-2">
          <Car size={32} className="text-white" />
          <span className="text-2xl font-bold">{BRAND.name}</span>
        </div>
        <div className="hidden md:flex space-x-8">
          <a href="#vehicles" className="hover:text-gray-200 transition-colors">
            {t('header.vehicles')}
          </a>
          <a href="#how-it-works" className="hover:text-gray-200 transition-colors">
            {t('header.howItWorks')}
          </a>
          <a href="#about" className="hover:text-gray-200 transition-colors">
            {t('header.about')}
          </a>
          <a href="#contact" className="hover:text-gray-200 transition-colors">
            {t('header.contact')}
          </a>
        </div>
        <div className="flex space-x-4">
          <button className="px-4 py-2 text-sm font-medium text-red-600 bg-white rounded-md hover:bg-gray-100 transition-colors">
            {t('header.login')}
          </button>
        </div>
      </nav>
    </header>
  );
}