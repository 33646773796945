import React from 'react';
import { Clock, Shield, ThumbsUp, Headphones } from 'lucide-react';
import { useTranslation } from 'react-i18next';

export function Features() {
  const { t } = useTranslation();

  const features = [
    {
      icon: Clock,
      translationKey: 'booking'
    },
    {
      icon: Shield,
      translationKey: 'insurance'
    },
    {
      icon: ThumbsUp,
      translationKey: 'quality'
    },
    {
      icon: Headphones,
      translationKey: 'support'
    }
  ];

  return (
    <section className="py-16 bg-gray-50">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold text-center mb-12">{t('features.title')}</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {features.map((feature, index) => (
            <div key={index} className="bg-white p-6 rounded-lg shadow-md">
              <feature.icon className="w-12 h-12 text-red-600 mb-4" />
              <h3 className="text-xl font-semibold mb-2">
                {t(`features.${feature.translationKey}.title`)}
              </h3>
              <p className="text-gray-600">
                {t(`features.${feature.translationKey}.description`)}
              </p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}