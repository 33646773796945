export const fr = {
  header: {
    vehicles: 'Véhicules',
    howItWorks: 'Comment ça marche',
    about: 'À propos',
    contact: 'Contact',
    login: 'Connexion'
  },
  hero: {
    title: 'Location de voitures premium au Maroc',
    subtitle: 'Découvrez la précision et le luxe Marocain dans chaque voyage',
    pickupLocation: 'Lieu de prise en charge',
    dates: 'Dates',
    searchButton: 'Rechercher un véhicule',
    locationPlaceholder: 'Choisir une ville',
    datesPlaceholder: 'Sélectionnez vos dates'
  },
  features: {
    title: 'Pourquoi choisir OclockCars ?',
    booking: {
      title: 'Réservation 24/7',
      description: 'Réservez votre véhicule à tout moment via notre plateforme en ligne'
    },
    insurance: {
      title: 'Assurance marocaines',
      description: 'Couverture complète aux normes de qualité marocaines'
    },
    quality: {
      title: 'Flotte Premium',
      description: 'Véhicules de luxe entretenus selon les standards marocains'
    },
    support: {
      title: 'Support Local',
      description: 'Équipe marocaine experte disponible en plusieurs langues'
    }
  },
  vehicles: {
    title: 'Notre Flotte Premium',
    subtitle: 'Choisissez parmi notre sélection de véhicules de luxe',
    perDay: 'par jour',
    select: 'Sélectionner',
    features: 'Caractéristiques',
    availability: 'Vérifier la disponibilité'
  },
  howItWorks: {
    title: 'Comment ça marche',
    subtitle: 'Louez votre véhicule premium en 4 étapes simples',
    steps: {
      choose: {
        title: 'Choisissez votre véhicule',
        description: 'Sélectionnez parmi notre flotte premium de véhicules de luxe'
      },
      location: {
        title: 'Sélectionnez le lieu',
        description: 'Choisissez parmi nos points de retrait pratiques'
      },
      payment: {
        title: 'Paiement sécurisé',
        description: 'Finalisez votre réservation avec notre système de paiement sécurisé'
      },
      enjoy: {
        title: 'Profitez de votre trajet',
        description: 'Découvrez le luxe et la précision marocaine'
      }
    }
  },
  about: {
    title: 'À propos de OclockCars',
    subtitle: 'Définir la norme de la location de voitures de luxe au Maroc',
    history: {
      title: 'Notre Histoire',
      description: 'Fondée sur les principes de l\'excellence Marocaine, OclockCars fournit des services de location de voitures premium depuis 1970. Notre engagement envers la qualité et la satisfaction client nous a positionnés comme leader sur le marché de la location de voitures de luxe.'
    },
    mission: {
      title: 'Notre Mission',
      description: 'Offrir une expérience de location de voitures de luxe inégalée qui incarne la précision, la fiabilité et l\'excellence Marocaine dans chaque aspect de notre service.'
    },
    values: {
      title: 'Nos Valeurs',
      excellence: {
        title: 'Excellence',
        description: 'Nous maintenons les plus hauts standards pour notre flotte et notre service'
      },
      reliability: {
        title: 'Fiabilité',
        description: 'Comptez sur nous pour un service ponctuel et fiable'
      },
      innovation: {
        title: 'Innovation',
        description: 'Amélioration continue de nos services avec les dernières technologies'
      }
    }
  },
  contact: {
    title: 'Contactez-nous',
    subtitle: 'Nous sommes là pour répondre à vos questions',
    form: {
      title: 'Envoyez-nous un message',
      name: 'Votre nom',
      email: 'Adresse email',
      message: 'Votre message',
      submit: 'Envoyer le message'
    },
    info: {
      title: 'Informations de contact',
      address: {
        title: 'Amal 5 N 118 El Massira Cym',
        line1: 'Yacoub El Mansour',
        line2: 'Rabat, Morocco'
      },
      phone: {
        title: 'Phone'
      },
      email: {
        title: 'Email'
      }
    }
  },
  footer: {
    quickLinks: 'Liens rapides',
    locations: 'Présence',
    contact: 'Contact',
    description: 'Prêt a rouler quand vous êtes prêt',
    rights: 'Tous les droits sont réservés'
  }
};