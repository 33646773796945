import React from 'react';
import { useTranslation } from 'react-i18next';
import { VehicleCard } from '../components/vehicles/VehicleCard';
import { vehicles } from '../config/vehicles';

export function Vehicles() {
  const { t } = useTranslation();

  const handleVehicleSelect = (id: string) => {
    console.log('Selected vehicle:', id);
    // TODO: Implement booking flow
  };

  return (
    <div className="py-16 bg-gray-50">
      <div className="container mx-auto px-4">
        <h1 className="text-4xl font-bold text-center mb-4">{t('vehicles.title')}</h1>
        <p className="text-gray-600 text-center mb-12">{t('vehicles.subtitle')}</p>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {vehicles.map((vehicle) => (
            <VehicleCard
              key={vehicle.id}
              vehicle={vehicle}
              onSelect={handleVehicleSelect}
            />
          ))}
        </div>
      </div>
    </div>
  );
}