import React from 'react';
import { useTranslation } from 'react-i18next';
import { BRAND } from '../../config/constants';
import { Car } from 'lucide-react';

export function Footer() {
  const { t } = useTranslation();
  const currentYear = new Date().getFullYear();

  return (
    <footer className="bg-gray-900 text-white py-12">
      <div className="container mx-auto px-4">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
          <div>
            <div className="flex items-center space-x-2 mb-4">
              <Car size={24} />
              <span className="text-xl font-bold">{BRAND.name}</span>
            </div>
            <p className="text-gray-400">
              {t('footer.description')}
            </p>
          </div>
          
          <div>
            <h3 className="text-lg font-semibold mb-4">{t('footer.quickLinks')}</h3>
            <ul className="space-y-2">
              <li>
                <a href="#vehicles" className="text-gray-400 hover:text-white transition-colors">
                  {t('header.vehicles')}
                </a>
              </li>
              <li>
                <a href="#how-it-works" className="text-gray-400 hover:text-white transition-colors">
                  {t('header.howItWorks')}
                </a>
              </li>
              <li>
                <a href="#about" className="text-gray-400 hover:text-white transition-colors">
                  {t('header.about')}
                </a>
              </li>
            </ul>
          </div>
          
          <div>
            <h3 className="text-lg font-semibold mb-4">{t('footer.locations')}</h3>
            <ul className="space-y-2">
              {BRAND.locations.map((location) => (
                <li key={location} className="text-gray-400">
                  {location}
                </li>
              ))}
            </ul>
          </div>
          
          <div>
            <h3 className="text-lg font-semibold mb-4">{t('footer.contact')}</h3>
            <address className="text-gray-400 not-italic">
              Amal 5 N 118 El Massira Cym<br />
              Yacoub El Mansour, Rabat, Morocco<br />
              <a href="tel:+212661978451" className="hover:text-white transition-colors">
                +212-661-978451
              </a><br />
              <a href="mailto:info@oclockcars.com" className="hover:text-white transition-colors">
                info@oclockcars.com
              </a>
            </address>
          </div>
        </div>
        
        <div className="border-t border-gray-800 mt-8 pt-8 text-center text-gray-400">
          <p>&copy; {currentYear} {BRAND.name}. {t('footer.rights')}</p>
        </div>
      </div>
    </footer>
  );
}